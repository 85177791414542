<template>
	<div class="input-wrapper">
		<div v-if="title" class="title">
			{{ title }}
			<span v-if="subtitle">
				<br />
				{{ subtitle }}
			</span>
		</div>
		<div class="input-body" :class="{ 'input-body--error': errors && errors.$error }">
			<MultiSelect
				:max-height="200"
				:max="max"
				:required="required"
				:options="formList"
				:preserve-search="true"
				track-by="id"
				label="name"
				:multiple="multiple"
				:close-on-select="!multiple"
				:disabled="disabledIn || disabled"
				:placeholder="placeholder"
				:class="classList"
				class="multiselect__check"
				v-model="value"
			>
				<template slot="option" slot-scope="{ option }">{{ option.name }}</template>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				<template slot="noResult">{{ $t('components.not_found') }}</template>
				<template slot="noOptions">{{ $t('components.not_found') }}</template>
				<template slot="placeholder">{{ placeholder || $t('placeholder.choose') }}</template>
			</MultiSelect>
			<input
				v-if="!multiple"
				type="text"
				:name="name"
				:required="required"
				:value="value ? value.id : ''"
				class="hidden"
			/>
			<input
				v-else
				type="text"
				:name="name"
				:required="required"
				:value="'[' + idValues(value) + ']'"
				class="hidden"
			/>
		</div>
		<div v-if="errors && !errors.required" class="input-required">
			{{ $t('system_message.required_message') }}
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MultiSelect from 'vue-multiselect'

export default {
	name: 'RegionSelect',
	components: {
		MultiSelect,
	},
	data() {
		return {
			value: null,
			disabledIn: false,
		}
	},
	props: {
		modelValue: {
			type: [Number, Array],
			default: null,
		},
		categoryValue: {
			type: [Number, Array],
			default: null,
		},
		confinesValue: {
			type: Array,
			default: Array,
		},
		errors: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		userBind: {
			type: Array,
			default: Array,
		},
		max: {
			type: Number,
			default: null,
		},
		classList: {
			type: Array,
			default() {
				return []
			},
		},
	},
	emits: ['update:modelValue', 'update:modelObjectsName'],
	watch: {
		categoryValue() {
			this.value = null
			this.$emit('update:modelValue', null)
		},
		value(item) {
			if (item && !this.multiple) {
				this.$emit('update:modelValue', item.id)
				this.$emit('update:modelObjectsName', item.name)
			} else if (item) {
				this.$emit('update:modelValue', this.idValues(item))
				this.$emit('update:modelObjectsName', this.idNames(item))
			} else {
				this.$emit('update:modelValue', null)
			}
		},
		_getRegionList: {
			handler() {
				this.setValues()
			},
			immediate: true,
		},
		modelValue: {
			handler() {
				this.setValues()
			},
			immediate: true,
		},
	},
	computed: {
		...mapGetters(['_getRegionList']),
		formList() {
			if (this.userBind?.length > 0) {
				let list = this._getRegionList.filter((element) => {
					return this.userBind.find((e) => {
						if (e.id == element.id) {
							//this.disabled=true;
							this.value = element
							return true
						}
					})
				})
				return list
			}

			if (this.allOptions) {
				return [{ id: 0, name: 'Все...' }, ...this._getRegionList]
			}

			return this._getRegionList
		},
	},
	beforeMount() {
		this._fetchRegionList()
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		setValues() {
			if (!this.modelValue || !this.modelValue.length) {
				this.value = null
			}
			if (this._getRegionList) {
				let multi = []
				this._getRegionList.forEach((e) => {
					if (this.modelValue && !this.multiple) {
						if (e.id == this.modelValue) {
							this.value = e
						}
					}
					if (this.modelValue && this.multiple) {
						this.modelValue.find((id) => {
							if (e.id == id) {
								multi.push(e)
							}
						})
						this.value = multi
					}
				})
			}
		},
		idNames(array) {
			let names = []
			array.find((element) => {
				names.push(element.name)
			})
			return names
		},
		idValues(array) {
			let ids = []
			array?.find((element) => {
				ids.push(element.id)
			})
			return ids
		},
	},
}
</script>

<style>
.title {
	font-weight: 600;
	font-size: 12px;
	color: #0a091d;
	margin-bottom: 8px;
	line-height: 1.2;
}
.title span {
	color: #708295;
}
.input-body {
	position: relative;
}
.input-body .multiselect__tags {
	padding-left: 20px;
	border: 1px solid #d3e7fb;
	border-radius: 5px;
	background: #ffffff;
	font-weight: 400;
	font-size: 14px;
	color: #0a091d;
	line-height: 1.2;
	width: 100%;
}
.input-body .multiselect--disabled .multiselect__tags {
	border: 1px solid #d3e7fb;
}
.input-body .multiselect--disabled .multiselect__placeholder {
	margin-bottom: 0;
}
.input-body .multiselect__single {
	margin-bottom: 0;
}
.input-body--error.input-body .multiselect__tags {
	border-color: #f3574d;
}
.input-body--error + .input-required {
	display: block;
}

.input-body .input-linck .multiselect__tags {
	border: none;
	padding-left: 4px;
}

.input-body .input-linck {
	padding: 0 !important;
}

.input-body .input-linck .multiselect__single {
	font-size: 12px;
}

.input-body .input-linck .multiselect__placeholder {
	padding-left: 8px;
	font-size: 12px;
	color: #757575;
}
.hidden {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
}
</style>
